import axios from 'axios'

const API = 'https://a.klaviyo.com/api/v2/list/Rcpb9g/members'
axios.defaults.headers.common['Content-Type'] = 'application/json'
// axios.defaults.headers.common['Authorization'] = 'Basic cmFjaGl0QHBpbmNoa2l0LmNvbTpIdW1hbkZhY3RvcnMyMDIwQA=='

const klaviyoHttp = {
  addMember: async (email) => {
    const data = {
      url: API,
      method: 'post',
      headers: {
        "Content-Type": "application/json"
      },
      data: {
        api_key: "pk_03ef86f0569560ef8664a028c546611233",
        profiles: [
            {
                email
            }
        ]
      }
    }
    try {
      let results = await axios.post(`https://calm-island-12834.herokuapp.com/api`, data)
      console.log("results", results)
      if(results.status && results?.status === 200){
        return true
      }
      return false
    }catch(err) {
      console.log(err)
      return null
    }
  }
}

export default klaviyoHttp