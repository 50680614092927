/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Link } from 'gatsby'

import H1 from 'elements/H1'

import './index.scss'

const NavigationBlock = ({ title, subBlocks }) => {
  return (
    <div className="navigation-section">
      <div className="links-section">
        {subBlocks.map( ({path, label}) => {
          if (label === 'Home') {
            const to = `/${path}`
            return (
              <Link
                sx={{color: 'white',
                    fontSize: 'small',
                    fontFamily: 'univers-lt-std-55-roman'
                }}
                key={label}
                to={to}
              >{label}</Link>
            )
          } else if (label === "Email Us") {
            return <a
              sx={{color: 'white',
                  fontSize: 'small',
                  fontFamily: 'univers-lt-std-55-roman'
              }}
              href={path}
            >{label}</a>
          }
        })}
      </div>
    </div>
  )
}

export default NavigationBlock