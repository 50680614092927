/** @jsx jsx */
import { jsx } from 'theme-ui'
import { useStaticQuery, graphql, Link } from 'gatsby'
import PropTypes from 'prop-types'
import { Location } from '@reach/router'
import { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBars,
  faTimes
} from '@fortawesome/free-solid-svg-icons'

import Button from 'elements/Button'
import logoSVG from 'images/logo.svg'

import './index.scss'

const Header = ({isHome, notifyMeHandler}) => {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      contentfulNavigation(title: { eq: "Default" }) {
        pageLinks {
          label
          path
          type
          highlighted
        }
        title
      }
    }
  `)

  const [open, setOpen] = useState(false);
  const handleMobileIcon = () => {
    setOpen(!open);
  } 
  useEffect(() => {
    if(open) {
      window.document.body.style.overflow = 'hidden';
    }else {
      window.document.body.style.overflow = 'visible';
    }
  }, [open])
  return (
    <Location className='navbar'>
      {({ location }) => (
        <header sx={{ 
          backgroundColor: open ? 'white' : 'unset', 
          position: open ? 'fixed' : 'none'
        }}>
          <div id="my-nav-body" className={ open ? 'nav-body active' : 'nav-body' }>
            <div className={isHome ? "logo home" : 'logo'}>
              <Link to="/" >
                <img src={logoSVG} className="logo-image" />
              </Link>
              <div className="mobile-icon" onClick={handleMobileIcon} >
                <FontAwesomeIcon icon={faBars} color="#482d8d" size="lg" id="bars"/>
                <FontAwesomeIcon icon={faTimes} color="#482d8d" size="lg" id="times"/>
              </div>
            </div>
            <Link
              to="/"
              sx={{
                color: 'primary',
                fontSize: 'small',
                fontWeight: location?.pathname === '/'  ? 700 : 'normal',
                opacity: (location?.pathname === '/') ? 1 : 0.9,
                fontFamily: (location?.pathname === '/') ? 'univers-lt-std-65-bold' : 'univers-lt-std-55-roman',
              }}
              className="nav-body-tag nav-body-tag-home"
            >Home
            </Link>
            {data?.contentfulNavigation?.pageLinks?.map(({ label, path, type, highlighted }) => {
              const to = `/${path}`;
              if (path !== 'contact') return <div />;
              return (
                <Link
                  key={label}
                  to={to}
                  sx={{
                    color: 'primary',
                    fontSize: 'small',
                    fontWeight: location?.pathname?.startsWith(to)  ? 700 : 'normal',
                    opacity: (location?.pathname?.startsWith(to) || highlighted) ? 1 : 0.9,
                  }}
                  className="nav-body-tag"
                >{label}</Link>
              )
            })}
            <Button size="small" className="nav-notify-btn" onClick={() => notifyMeHandler && notifyMeHandler()} noLink>
              Notify Me
            </Button>
          </div>
        </header>
      )}
    </Location>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
