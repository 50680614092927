/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import { useStaticQuery, graphql } from 'gatsby'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { fromPairs } from 'lodash'
import { ThemeProvider } from 'theme-ui'
import CookieConsent from 'react-cookie-consent'
import favicon from 'images/pinch-icon.png'
import Helmet from 'react-helmet'
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './Header'
import Footer from './Footer1'
import GetItFirstModal from 'components/GetItFirstModal'

import './index.scss'

const Layout = ({ children, isHome, noHeader, noFooter, showOrder, closeOrder }) => {
  const data = useStaticQuery(graphql`
    query ThemeQuery {
      contentfulTheme(title: { eq: "Default Theme" }) {
        colors {
          title
          color
        }
        fontSizes {
          title
          size
        }
        contentful_id
      }
    }
  `)

  const theme = {
    colors: fromPairs(data?.contentfulTheme?.colors?.map(({ title, color }) => [title.toLowerCase(), color])),
    fontSizes: fromPairs(data?.contentfulTheme?.fontSizes?.map(({ title, size }) => [title.toLowerCase(), size]))
  }
  
  if (typeof window !== "undefined") {
    require("smooth-scroll")('a[href*="#"]')
  }

  const [show, setShow] = useState(false)
  const [email, setEmail] = useState('')

  const closeHandler = () => {
    setShow(false);
    closeOrder && closeOrder()
  }

  useEffect(() => {
    setShow(showOrder)
  }, [showOrder])

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <title>pinch - COVID-19 Antibody Home Test</title>
        <link rel="icon" href={favicon} />
      </Helmet>
      {!noHeader && (<Header isHome={isHome} notifyMeHandler={() => setShow(true)}/>)}
      <CookieConsent
        enableDeclineButton
        location="bottom"
        buttonText="Accept"
        declineButtonText="Decline"
        cookieName="gatsby-gdpr-google-analytics"
        buttonClasses="cookie-consent-btn"
        declineButtonClasses="cookie-consent-decline-btn"
        style={{
          backgroundColor: 'azure',
        }}
        containerClasses="cookie-consent"
        expires={1}
      >
        <div className="cookie-consent-text">
          <span className="cookie-consent-title">Cookies</span>
          <span className="cookie-consent-desc">We use cookies to personalize content and ads, to provide social media features and to analyse our traffic. We also share information about your use of our site with our social media, advertising and analytics partners who may combine it with other information that you’ve provided to them or that they’ve collected from your use of their services.</span>
        </div>
      </CookieConsent>
      <main>
        {show && (
          <GetItFirstModal initEmail={email} onClose={closeHandler}/>
        )}
        {children}
      </main>
      {!noFooter && (<Footer notifyMeHandler={(email)=> {setEmail(email); setShow(true)}} />)}
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  isHome: PropTypes.bool,
  noHeader: PropTypes.bool,
  noFooter: PropTypes.bool,
}

export default Layout
