/** @jsx jsx */
import { jsx } from 'theme-ui'
import { useStaticQuery, graphql } from 'gatsby'
import { useState } from 'react'

import H1 from 'elements/H1'
import Button from 'elements/Button'
import Text from 'elements/Text'

import logoImg from 'images/icon/logo.png'
import closeImg from 'images/icon/close.png'
import successMark from 'images/icon/successMark.svg'
import { emailValidator } from 'services/util'

import klaviyoHttp from "../../services/klaviyo"

import "./index.scss"

const GetItFirstModal = ({ onClose, initEmail }) => {
  const data = useStaticQuery(graphql`
    query GetItFirstQuery {
      contentfulPageBlock(title: { eq: "Get it first!" }) {
        title
        description {
          description
        }
        subBlocks {
          ... on ContentfulPageBlock {
            id
            input {
              placeholer
            }
            title
            description {
              description
            }
          }
        }
        button {
          label
        }
      }
    }
  `)
  const { title, description, subBlocks, button } = data.contentfulPageBlock

  const [email, setEmail] = useState(initEmail)
  const [status, setStatus] = useState('none')
  const [error, setError] = useState('')

  const handleInput = e => {
    setError('')
    setEmail(e.target.value)
  }

  const submit = async () => {
    if( !email || !emailValidator(email) ) {
      setError('not validated!')
      return
    }
    setStatus('loading')
    let results = await klaviyoHttp.addMember(email)
    if(results) {
      setStatus('success')
    }else {
      setStatus('failure')
    }
  }

  return (
    <div className="get-first-modal">
      <div className="get-first-modal-header">
        <img src={logoImg} className="logo-icon"></img>
        <div className="get-first-close" onClick={() => onClose()}>
          <Text size="medium-minus" color="black" className="close-text">
            Close
          </Text>
          <img src={closeImg} className="close-icon"></img>
        </div>
      </div>
      { status === 'none' &&
        <div className="get-first-body">
          <H1 size="large-minus" className="get-modal-title">
            {title}
          </H1>
          <Text size="small-plus" className="get-modal-description">
            {description.description}
          </Text>
          <div className="get-modal-custom-input">
            <label htmlFor="email">EMAIL</label>
            <input
              type="email"
              name="email"
              id="email"
              value={email}
              placeholder={subBlocks[0]?.input?.placeholer}
              onChange={handleInput}
            />
          </div>
          { error !== '' && <span className="get-modal-error">{error}</span>}
          <Button size="medium" className="get-btn" onClick={submit} noLink>
            {button?.label}
          </Button>
        </div>
      }
      { status === 'loading' &&
        <div className="get-first-body">
          <H1 size="large-minus">
            Subscribing...
          </H1>
          <Text size="small-plus" className="get-modal-description">
            Wait a min
          </Text>
        </div>
      }
      { status === 'success' &&
        <div className="get-first-body get-first-body-success">
          <div className="success-mark">
            <img src={successMark} alt="" />
          </div>
          <H1 size="large-minus" color="#65ae78">
            Congratulations!
          </H1>
          <Text size="small-plus" family="Pangram">
            You have successfully subscribed!
          </Text>
        </div>
      }
      { status === 'failure' &&
        <div className="get-first-body">
          <H1 size="large-minus" color="#ca2535" className="get-modal-title">
            I am sorry, Failed!
          </H1>
          <Text size="small-plus" className="get-modal-description">
            You failed to subscribe. Try again...
          </Text>
          <Button size="medium" className="get-btn" onClick={() => setStatus('none')} noLink>
            Try Again
          </Button>
        </div>
      }
    </div>
  )
}

export default GetItFirstModal
