export const emailValidator = (mail) => {
 if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail))
  {
    return (true)
  }
    return (false)
}

export const stripHtml = (html) => {
  let tmp = document.createElement("DIV")
  tmp.innerHTML = html
  document.body.append(tmp)
  const text = tmp.innerText || ""
  document.body.removeChild(tmp)
  return text
}

export const removeNonAscii = (str) => {
  if ((str===null) || (str===''))
    return false;
  else
    str = str.toString();
  return str.replace(/[^\x20-\x7E]/g, ' ').slice(0, 100).concat('...')
}