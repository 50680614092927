/** @jsx jsx */
import { jsx } from 'theme-ui'
import { useState } from 'react'

import H1 from 'elements/H1'

import './index.scss'

const SubscribeBlock = ({ title, description, input, notifyMeHandler }) => {
  const [email, setEmail] = useState('')

  const handleClick = (e) => {
    e.stopPropagation()
    notifyMeHandler(email)
  }
  return (
    <div className="subscribe-block">
      <H1 size="small-plus" family="univers-lt-std-65-bold" color="white">{title}</H1>
      <div>
        <input 
          type="text" 
          sx={{
            fontSize: 'small',
            color: 'white',
          }} 
          placeholder={input.placeholer}
          value={email}
          onChange={(e)=>setEmail(e.currentTarget.value)}
        />
        <span 
          className="subscribe-block-icon" 
          sx={{color: 'white'}}
          onClick={handleClick}
        >
          &#x2192;
        </span>
      </div>
    </div>
  )
}

export default SubscribeBlock